
import Vue from "vue"
import axios from "axios"
import { Message } from "@/store/types"
import { dateTimeFrancaise, sqlDatetime, variables } from "@/plugins/variables"

export default Vue.extend({
  name: "journal",
  data() {
    return {
      messages: [] as Message[],
      utilisateur: this.$store.state.utilisateur,
      now: sqlDatetime(new Date()),
      sj_contenus: "",
      unsubscribe: null as any,
      nouveauMessage: false,
      dictionnaire: [] as any[]
    }
  },
  methods: {
    initialiser() {
      const url = this.$store.state.ajaxurl + "ajax_appli.php"
      axios
        .get(url, {
          params: {
            action: "MESSAGE",
            date: this.$store.state.jour
          }
        })
        .then(response => {
          this.messages = response.data.messages
          for (let i = 0; i < this.messages.length; i++) {
            this.messages[i].modification = false
          }
          this.estAuteur()
        })
    },
    enregistrerMessage(sj_id: number) {
      const url = this.$store.state.ajaxurl + "ajax_appli.php"
      let date
      if (sj_id == 0) {
        sj_id = new Date().getTime()
        date = this.now
      } else {
        date = this.messages.find((m: Message) => m.sj_id == sj_id)?.sj_datetime
      }

      axios
        .get(url, {
          params: {
            action: "ENREGISTRER_MESSAGE",
            sj_id: sj_id,
            sj_contenus: this.sj_contenus,
            date: date,
            ut_id: this.trouverUtilisateurNom(this.utilisateur)
          }
        })
        .then(response => {
          if (response.data.Erreur == false) {
            this.nouveauMessage = false
            this.sj_contenus = ""
            this.initialiser()
          } else {
            console.log("Fonctionne pas")
          }
        })
    },
    modifierMessage(id: number, mode: string) {
      if (mode == "modifier") {
        this.messages[id].modification = true
        this.sj_contenus = this.messages[id].sj_contenus
      } else {
        this.messages[id].modification = false
      }
      this.messages.push({ sj_id: 0, sj_datetime: "2020-02-02 02:02:02", sj_contenus: "test", ut_id: 0, modification: false, auteur: false })
      this.messages.pop()
    },
    supprimerMessage(id: number) {
      const url = this.$store.state.ajaxurl + "ajax_appli.php"
      axios
        .get(url, {
          params: {
            action: "SUPPRIMER_MESSAGE",
            sj_id: id
          }
        })
        .then(response => {
          if (response.data.Erreur == true) {
            console.log("Erreur suppression")
          } else {
            const indexMessage: number = this.messages.findIndex((m: Message) => m.sj_id == id)
            this.messages.splice(indexMessage, 1)
          }
        })
    },
    trouverUtilisateur(ut_id: number) {
      const unSalarie = this.$store.state.salaries.find((s: any) => s.ut_id == ut_id)
      return unSalarie.ut_prenom + " " + unSalarie.ut_nom
    },
    trouverUtilisateurNom(ut_nomPrenom: string) {
      const separation = ut_nomPrenom.split(" ")
      return this.$store.state.salaries.find((s: any) => s.ut_nom == separation[1] && s.ut_prenom == separation[0]).ut_id
    },
    dateHeureFrancaise(dte: string) {
      return dateTimeFrancaise(dte)
    },
    estAuteur() {
      for (let i = 0; i < this.messages.length; i++) {
        if (this.trouverUtilisateur(this.messages[i].ut_id) == this.utilisateur) {
          this.messages[i].auteur = true
        } else {
          this.messages[i].auteur = false
        }
      }
    },
    choixlangue(lang: string) {
      switch (lang) {
        case "FR":
          this.dictionnaire = ["Message", "Nouveau message", "Modifier message", "Enregistrer", "Modifier", "le"]
          break
        case "AL":
          this.dictionnaire = ["Nachricht", "Neue Nachricht", "Nachricht bearbeiten", "Speichern", "Bearbeiten", "an"]
          break
      }
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "majJour":
          this.initialiser()
          break
        case "majLangue":
          this.choixlangue(this.$store.state.lang)
          break
      }
    })
  },
  mounted() {
    this.initialiser()
    this.choixlangue(variables.langue)
  },
  beforeDestroy() {
    this.unsubscribe = []
  }
})
